// LOADER
.loader{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  background-color: hsla(0, 0%, 8%, .65);
  overflow: hidden;
}
.loader .spinner-border {
  color: hsla(0, 0%, 8%, .65);
}
.loader p{
  color: hsla(360, 100%, 100%, 1);
  margin-top: 1rem;
}

.no-scroll{
  overflow: hidden;
}