
// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */

html {
    box-sizing: border-box;
    font-size: 15px;
}


/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

*,
*::before,
*::after {
    box-sizing: inherit;
}

ul.nostyle {
    list-style: none;
    padding-left: 0;

    li{
        line-height: 2rem;
        margin-bottom: 2rem;
    }
    i, svg{
    margin-right: 1rem;
    margin-bottom: .7rem;
    }
}

ul, ol {
    margin: 1.5rem 0 2.5rem;
    li{
        margin-bottom: .8rem;
            ul, ol{
            margin-top: .8rem;
        }
    }
}

table {
    max-width: 100% !important;
    width: 100% !important;
    margin: 1rem auto 2rem;
    border-color: $border-color !important;
       td{
            padding: .5rem;
        }
}

img{
    max-width: 100%;
}
.wrapper {
    position: relative;
}
// alerts
.alert {
    text-align: center;
    margin-bottom:0.3rem;  
}

#whatsapp {
    position: fixed;
    bottom: 10px;
    right: 15px;
    z-index: 1050;
    img {
        width: 4rem;
    }
}