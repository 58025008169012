
// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
/// Regular font family
/// @type List
$text-font-stack: 'Lato', sans-serif !default;
$button-font-size:1rem;
$button-font-size-small:0.9rem;
$button-font-desktop:1.1rem;
$font-weight:(
   regular:400,
   medium:700,
   bold:900
);

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New',
'DejaVu Sans Mono',
'Bitstream Vera Sans Mono',
'Monaco',
monospace !default;


/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: ( 'small': 320px, 'medium': 768px, 'large': 1024px, ) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;

// COLORES
$color-header: #1E4356;


$light-base:hsla(360, 100%, 100%, 1);
$dark-base: hsla(0, 0%, 8%, 1);
$primary-color: hsla(87, 55%, 52%, 1);
$primary-color-dark: hsla(hue($primary-color), 62%, 41%, 1);
$primary-color-highlight: hsla(105, 57%, 51%, 1);
$secondary-color: hsla(215, 75%, 30%, 1);
$secondary-color-light:hsla(hue($secondary-color), 80%, 38%, 1);
$secondary-color-dark1:hsla(hue($secondary-color), 98%, 28%, 1);
$secondary-color-dark2: hsla(hue($secondary-color), 98%, 23%, 1);
$text-color: hsla(0, 0%, 33%, 1);
$text-color-light: hsla(hue($text-color), 100%, 100%, 1);
$text-color-notes: hsla(hue($text-color), 0%, 44%, 1);
$text-color-dark: hsla(hue($text-color), 0%, 23%, 1);
$text-footer:hsla(hue($text-color), 0%, 70%, 1);
$border-color: hsla(0, 0%, 65%, 1);
$border-color-light: hsla(hue($border-color), 0%, 94%, 1);
$border-color-dark: hsla(hue($secondary-color), 0%, 44%, 1);
$border-collapse:hsla(0, 0%, 0%, 0.125);
$loader: hsla(0, 0%, 8%, .65);
$white:#ffffff;
$placeholder-color:hsla(hue($text-color), 0%, 76%, 1);

//Colores servicios
$color-naranja: hsl(36,85.2%,47.8%);
$color-verde: hsl(70,79.5%,40.2%);
$color-azul: hsl(214,74.3%,29%);
$color-rojo: hsl(2,65.2%,50.4%);

// headings
$heading-color:$secondary-color;

// border radius
$border-radius: 4px;
$desktop-input-radius:20px;
$desktop-select-radius:20px;
$desktop-bg-input: hsla(200, 22%, 92%, 1);

// shadows mobile
$box-shadow: hsla(hue($dark-base), 0%, 0%, .2);
$box-shadow-light: hsla(hue($dark-base), 0%, 0%, .1);
$box-shadow-none: hsla(hue($dark-base), 0%, 0%, 0);
$header-shadow: hsla(hue($dark-base), 0%, 0%, .2);

// shadows desktop
$box-shadow-desktop:hsla(hue($dark-base), 0%, 0%, .3);
$box-shadow-light-desktop:hsla(hue($dark-base), 0%, 0%, .2);
$header-desktop-shadow:hsla(hue($dark-base), 0%, 0%, .5);
$slider-shadow:hsla(hue($dark-base), 0%, 0%, .5);

// backgrounds
$bg-color: hsla(0, 0%, 98%, 1);
$bg-body:$bg-color;
$bg-header: $secondary-color;
$bg-dropdown-menu: $secondary-color-dark2;
$bg-dropdown-menu-hover: $secondary-color-dark1;
$bg-back-novedades: hsla(hue($secondary-color), 100%, 32%, 0.1);
$bg-modal: hsla(hue($dark-base), 0%, 0%, .5);

// buttons
// $button-color-1:$primary-color-highlight;
// $button-color-2:hsla(105, 60%, 61%, 1);
$button-color-2:hsla(136, 70%, 48%, 1);
$button-color-1:hsla(121, 70%, 35%, 1);
$button-back-color-1:$secondary-color;
$button-back-color-2:$secondary-color-dark1;
$button-border-radius: 2rem;
$contact-help-button-icon:hsla(213, 8%, 51%, 1);

// menu
$menu-item-static:hsla(hue($light-base), 100%, 100%, 0.8);
$menu-item-hover:$light-base;


// Height calls to action
$height-cta: 46px;
$height-select2: 46px;
$height-cta-sm:44px;
