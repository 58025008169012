.btn-servicios {
  border: 0;
  font-size: 22px;
  padding: .5rem 2rem;
  background: #68A4C4;
  color: #fff;
  transition: 0.3s;
  border-radius: 2rem;
  &:hover {
    background: #468db3;
    color: #fff;
  }
}